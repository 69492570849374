<template>
  <v-container>
    <!-- Banner header -->
    <Banner :title="'Oficinas'">
      <template v-slot:actions>
        <v-btn v-show="permissionsBannerActions.create.includes(user.role)" @click="handleBtnOpenDialogCreateOffice()">
          <v-icon>add</v-icon>
        </v-btn>
      </template>
    </Banner>

    <!-- Data table admins -->
    <v-card class="my-8">
      <DataTable
        :headers="tableHeaders"
        :items="offices"
        :totalItems="$store.state.offices.totalCountOffices"
        @clickNextIcon="handleNextIcon"
        @clickPrevIcon="handlePrevIcon"
        @inputSearch="handleSearch"
        @changeItemsPerPage="getOffices"
      >
        <template v-slot:[`item.id`]="{ item }">
          <router-link :to="`/oficinas/${item.id}`">{{ item.id }}</router-link>
        </template>
        <template v-slot:[`item.partner`]="{ item }">
          <router-link :to="`/partners/${item.partner.id}`">{{ item.partner.name }}</router-link>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ formatDateL(item.created_at) }}
        </template>
      </DataTable>
    </v-card>

    <!-- Dialog create office -->
    <DialogBox :model="dialogCreateOffice">
      <template slot="toolbar">
        <span>Crear oficina</span>
      </template>
      <template slot="content">
        <v-form ref="formCreateOffice" v-model="formCreateOfficeValid">
          <v-text-field v-model="office.name" label="Nombre" :rules="formRules.textRules" />
          <v-autocomplete
            v-model="office.partner_id"
            :items="partners"
            item-text="name"
            item-value="id"
            :rules="[(v) => v != '' || 'Campo obligatorio']"
            label="Partner"
            @change="handlePartnerSelection()"
          />
          <v-text-field v-model="office.schedule" label="Horario" :rules="formRules.textRules" />
          <div>
            <p><b>Cuentas bancarias</b></p>
            <div v-for="(account, i) in office.accounts" :key="i" class="secondary pa-5 mb-2">
              <v-toolbar dense flat class="secondary pl-n5">
                <p class="mb-0">Cuenta {{ i + 1 }}:</p>
                <v-spacer />
                <v-btn v-if="i != 0 && i != 1" icon elevation="2" small color="red" @click="removeAccount(i)">
                  <v-icon small>delete</v-icon>
                </v-btn>
              </v-toolbar>
              <v-text-field v-model="account.bank" label="Banco" :rules="formRules.textRules" />
              <v-text-field v-model="account.number" label="Número de cuenta" :rules="formRules.textRules" />
              <v-select
                v-if="i !== 0 && i !== 1"
                v-model="account.type"
                :items="['FEES', 'OUTLAYS', 'EXPENSES', 'OTHER']"
                :rules="[(v) => v !== '' || 'Campo obligatorio']"
                label="Tipo"
              />
              <v-text-field v-else v-model="account.type" label="Tipo" readonly />
              <v-text-field
                v-model.number="account.balance"
                label="Saldo disponible"
                type="number"
                :rules="formRules.numberRulesAllowZero"
              />
              <v-text-field v-model.number="account.bail" label="Fianza" type="number" :rules="formRules.numberRulesAllowZero" />
              <v-text-field v-model="account.clabe" label="CLABE" />
            </div>
            <div class="text-right">
              <v-btn elevation="2" small @click="addAccount">
                <v-icon>add</v-icon>
              </v-btn>
            </div>
          </div>
          <v-autocomplete
            v-if="!_.isEmpty(office.partner_id)"
            v-model="office.city"
            :items="cities"
            item-text="name"
            item-value="id"
            label="Ciudad"
            :rules="formRules.textRules"
          />
          <v-text-field v-if="!_.isEmpty(office.city)" v-model="office.zone" label="Zona" :rules="formRules.textRules" />
          <v-text-field v-model="office.address.name" label="Dirección" :rules="formRules.textRules" />
        </v-form>
      </template>
      <template slot="actions">
        <v-btn text @click="closeDialog('dialogCreateOffice', 'formCreateOffice')">Cerrar</v-btn>
        <v-btn text @click="create()">Crear</v-btn>
      </template>
    </DialogBox>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import _ from "lodash";
import Banner from "@/components/elements/Banner";
import DataTable from "@/components/elements/DataTable";
import DialogBox from "@/components/elements/DialogBox";

export default {
  name: "Offices",
  components: {
    Banner,
    DataTable,
    DialogBox
  },
  mixins: [FormatDateMixin, FormRulesMixin],
  data() {
    return {
      // Banner actions
      permissionsBannerActions: {
        create: ["SUPERADMIN", "ADMIN"]
      },

      // Offices datatable
      tableHeaders: [
        { text: "Id", value: "id", sortable: false },
        { text: "Nombre", value: "name", sortable: false },
        { text: "Dirección", value: "address.name", sortable: false },
        { text: "Partner", value: "partner", sortable: false },
        { text: "Horario", value: "schedule", sortable: false },
        { text: "Fecha creación", value: "created_at" }
      ],
      permissionsTableActions: {
        uploadDoc: ["SUPERADMIN", "ADMIN"],
        edit: ["SUPERADMIN", "ADMIN"],
        delete: ["SUPERADMIN", "ADMIN"]
      },

      // Dialogs
      dialogCreateOffice: false,
      formCreateOfficeValid: false,

      // Fields of office
      partnerSelected: {},
      office: {
        name: "",
        partner_id: "",
        schedule: "",
        accounts: [
          {
            bank: "",
            number: "",
            clabe: "",
            type: "FEES",
            balance: 0,
            bail: 0
          },
          {
            bank: "",
            number: "",
            clabe: "",
            type: "OUTLAYS",
            balance: 0,
            bail: 0
          }
        ],
        city: "",
        zone: "",
        address: {
          coordinates: {
            lat: 0,
            lon: 0
          },
          name: ""
        }
      }
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      dbUser: (state) => state.user.dbUser,
      partners: (state) => state.partners.partners,
      offices: (state) => state.offices.offices,
      countries: (state) => state.locations.countries,
      cities: (state) => state.locations.cities
    }),
    _() {
      return _;
    },
    isCountryAndCountryPartnerDifferent() {
      const partnerSelected = this.partners.find((e) => e.id === this.office.partner_id);
      const countrySelected = this.countries.find((e) => e.id === this.office.country);
      return partnerSelected?.country !== countrySelected?.id;
    }
  },
  async created() {
    if (this.user.role === "SUPERTECHNICIAN") {
      await this.getOffices({ size: 10, sort: { created_at: "DESC" }, filter: { "partner.id": this.dbUser.technician.partner_id } });
    } else await this.getOffices({ size: 10, sort: { created_at: "DESC" } });
  },
  methods: {
    ...mapActions({
      getOffices: "offices/getOffices"
    }),

    async handlePrevIcon(elasticData) {
      await this.getOffices(elasticData);
    },

    async handleNextIcon(elasticData) {
      await this.getOffices(elasticData);
    },

    async handleSearch(elasticData) {
      await this.getOffices(elasticData);
    },

    // Create office
    async create() {
      if (this.$refs.formCreateOffice.validate()) {
        let officeFormattedData = { ...this.office };

        await this.$store.dispatch("offices/createOffice", officeFormattedData);
        await this.getOffices({ size: 10, sort: { created_at: "DESC" } });
        this.closeDialog("dialogCreateOffice", "formCreateOffice");
      }
    },

    // Add an account
    addAccount() {
      this.office.accounts.push({
        bank: "",
        number: "",
        clabe: "",
        type: "",
        balance: "",
        bail: ""
      });
    },

    // Remove an account
    removeAccount(index) {
      this.office.accounts.splice(index, 1);
    },

    async handleBtnOpenDialogCreateOffice() {
      await this.$store.dispatch("partners/getPartners");
      this.dialogCreateOffice = true;
    },

    async handlePartnerSelection() {
      this.partnerSelected = this.partners.find((e) => e.id === this.office.partner_id);
      this.office.city = null;
      await this.$store.dispatch("locations/getCities", { filter: { country: this.partnerSelected.country.id } });
    },

    // Close dialogs, clear and reset validation form fields
    closeDialog(dialogModel, formRef) {
      this.$refs[formRef].resetValidation();
      this[dialogModel] = false;

      this.office = this.$options.data().office;
    }
  }
};
</script>
